import React, { useState, useEffect, useRef, useCallback } from "react";
import { useUser } from "../../context/UserContext";

const styles = {
	container_types: {
		display: "flex",
		justifyContent: "space-around",
	},
	type_tag: {
		padding: "0vh",
		maxWidth: "content",
		// border: "1px solid red",
	},
	icons_container_style: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		alignSelf: "center",
		justifySelf: "center",
		justifyContent: "space-around",
		alignContent: "space-around",
	},
	icons_link_style: {
		minWidth: "100%",
		minHeight: "100px",
		border: "1px solid #cfcdcd",
		borderRadius: "10px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		margin: "0.5vw",
		color: "#6C6565",
	},
	link_style: {
		textDecoration: "none",
		color: "#6C6565 !important",
	},
	container_card: {
		display: "flex",
		alignItems: "center",
		minWidth: "150px",
		minHeight: "100px",
		margin: "0.5vw",
	},
};

function Tipo({ objeto }) {
	const [isEditing, setIsEditing] = useState(false);
	const [typeValue, settypeValue] = useState(objeto.tipo);
	const inputRef = useRef(null);
	const { user } = useUser();

	const handleUpdateType = useCallback(async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BASE_URL}/update/type/${user.encodedEmail}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ id: objeto.id, type: typeValue }),
				}
			);
			if (response.ok) {
				settypeValue(typeValue);
			} else {
				console.error(
					"Failed to update type:",
					response.status,
					response.statusText
				);
			}
		} catch (error) {
			console.error("Error during fetch:", error);
		}
		setIsEditing(false);
	});

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				isEditing &&
				inputRef.current &&
				!inputRef.current.contains(event.target)
			) {
				handleUpdateType();
			}
		};

		// Adiciona event listener quando o componente começa a editar
		document.addEventListener("mousedown", handleClickOutside);

		// Cleanup do event listener quando o componente deixa de ser editado
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isEditing, typeValue, handleUpdateType]);

	const toggleEditing = () => {
		setIsEditing(!isEditing);
	};

	const handleInputChange = (e) => {
		settypeValue(e.target.value);
	};

	const handleInputKeyDown = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			handleUpdateType();
		}
	};

	return (
		<div style={styles.container_card} onClick={toggleEditing}>
			{isEditing ? (
				<input
					style={styles.edit_type}
					ref={inputRef}
					type="text"
					value={typeValue}
					onChange={handleInputChange}
					onKeyDown={handleInputKeyDown}
					onBlur={handleUpdateType}
					autoFocus
				/>
			) : (
				// <div onClick={toggleEditing}>{typeValue}</div>

				<div style={styles.icons_container_style}>
					<div
						style={styles.icons_link_style}
						data-toggle="tooltip"
						data-placement="top"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="30"
							height="30"
							fill={objeto.color}
							viewBox="0 0 16 16"
						>
							<path d={objeto.icond1} />
							<path d={objeto.icond2} />
						</svg>
						<h6 style={{ textDecoration: "none", marginTop: "5px" }}>
							{typeValue}
						</h6>
					</div>
				</div>
			)}
		</div>
	);
}

export default Tipo;
