import React, { useState } from "react";
import Player from "../components/welcome/Player.js";
import Footer from "../components/common/Footer.js";
import Menu from "../components/common/Menu.js";
import { useUser } from "../context/UserContext.js";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import arrowRight from "../assets/images/arrow_right.svg";
import { callMsGraph } from "../services/graph.js";
import PopUpErrorLogin from "../components/welcome/PopUpErrorLogin.js";
import { Buffer } from "buffer";

const WelcomePage = () => {
	const { instance } = useMsal();
	const { user, setUser } = useUser();
	const navigate = useNavigate();
	const [popUpErro, setPopUpErro] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const handleLogin = (loginType) => {
		if (loginType === "popup") {
			instance
				.loginPopup({
					scopes: ["User.Read"],
				})
				.then(async (response) => {
					let profile = await callMsGraph(response.accessToken);
					// profile.officeLocation = "unidade genérica";
					// console.log(profile);
					if (
						profile.officeLocation !==
							"150 - Centro SENAI de Tecnologias Educacionais" &&
						profile.mail !== "rsiqueira@sesisp.org.br"
					) {
						throw new Error(
							"Login permitido somente para usuários da unidade 1.50"
						);
					}
					var encodedEmail = new Buffer.from(response.account.username);
					encodedEmail = encodedEmail.toString("base64");

					let role = await fetch(
						`${process.env.REACT_APP_BASE_URL}/find/user/${encodedEmail}`,
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify({ username: response.account.username }),
						}
					);
					if (role.status === 401) {
						throw new Error("Unauthorized Access");
					}
					role = await role.json();
					response.account.role = role?.ntec_catalogo_acesso.nivel ?? "COMMON";
					response.account.encodedEmail = encodedEmail;
					setUser(response.account);
					navigate("/home");
				})
				.catch((e) => {
					console.error(e.message);
					setErrorMessage(e.message);
					setPopUpErro(true);
				});
		} else if (loginType === "redirect") {
			instance.loginRedirect({
				scopes: ["User.Read"],
			});
		}
	};
	return (
		<>
			<Menu />
			<div className="container_principal_welcome">
				<div className="container_texto_welcome">
					{/* <div style={texto_welcome}>Bem vindo!</div> */}
					<div className="texto_banco">Banco de</div>
					<div className="texto_recursos">Recursos</div>
					<div className="texto_name">
						Escola <span>SENAI</span> de Educação Online
					</div>
					<button className="btn_login" onClick={() => handleLogin("popup")}>
						Entrar
						<img src={arrowRight} alt="seta para a direita" />
					</button>
				</div>
				<div className="container_video">
					<Player />
				</div>

				{popUpErro && (
					<PopUpErrorLogin
						erro={errorMessage}
						isOpen={popUpErro}
						onClose={() => setPopUpErro(false)}
					/>
				)}
			</div>
			<Footer />
		</>
	);
};

export default WelcomePage;
