import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUser } from "../context/UserContext.js";

const ProtectedRoute = ({ element: Element, roles }) => {
	const { user } = useUser();
	const location = useLocation();

	return user && roles.includes(user.role) ? (
		<Element />
	) : (
		<Navigate to="/" state={{ from: location }} replace />
	);
};

export default ProtectedRoute;
