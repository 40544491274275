import { useEffect, useState } from "react";
import React from "react";
import Tipo from "./Tipo.js";
import { useUser } from "../../context/UserContext.js";

const styles = {
	main_container: {
		boxShadow: "0px 1px 50px #B9B6B640",
		borderRadius: "10px",
		padding: "40px",
		minHeight: "min-content",
		fontFamily: "'Roboto', sans-serif",
		fontSize: "large",
		fontWeight: "bold",
		display: "flex",
		// justifyContent: "space-around",
		margin: "30px 0px",
		flexWrap: "wrap",
		overflow: "auto",
	},
};

const Tipos = () => {
	const [types, setTypes] = useState([]);
	const { user } = useUser();

	useEffect(() => {
		const setTipos = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BASE_URL}/list/types/${user.encodedEmail}`,
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
				if (response.status === 401) {
					throw new Error("Unauthorized Access");
				}
				const listOfTypes = await response.json();
				setTypes(listOfTypes);
			} catch (error) {
				console.error(`Erro ao buscar lista de Tipos`, error);
			}
		};
		setTipos();
	}, []);

	return (
		<div style={styles.main_container}>
			{types.map((e) => (
				<Tipo key={e.id} objeto={e} />
			))}
		</div>
	);
};

export default Tipos;
