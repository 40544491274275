import React from "react";

const ConfirmationPopup = ({ isOpen, onClose, onConfirm }) => {
	if (!isOpen) {
		return null;
	}

	return (
		<div className="RegisterPage_Confirmation_popup_container">
			<div className="RegisterPage_Content_Style">
				<p>Confirma o cadastro com as informações fornecidas?</p>
				<button className="RegisterPage_PopUp_confirm_btn" onClick={onConfirm}>
					Confirmar
				</button>
				<button className="RegisterPage_PopUp_cancel_btn" onClick={onClose}>
					Cancelar
				</button>
			</div>
		</div>
	);
};

export default ConfirmationPopup;
