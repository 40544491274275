import React, { useState, useEffect } from "react";
import CardTipo from "./CardTipo.js";
import FadeIn from "react-fade-in";
import { useUser } from "../../context/UserContext.js";

const TiposContainer = () => {
	const [tipos, setTipos] = useState([]);
	const { user } = useUser();

	useEffect(() => {
		// Busque os tipos na rota/tipos do backend
		const fetchTipos = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BASE_URL}/list/types/${
						user ? user.encodedEmail : "eEBzcC5zZW5haS5icg=="
					}`,
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
				if (response.status === 401) {
					throw new Error("Unauthorized Access");
				}
				const tiposData = await response.json();
				console.log(tiposData);
				setTipos(tiposData);
			} catch (error) {
				console.error("Erro ao buscar tipos:", error);
			}
		};

		fetchTipos();
	}, []);

	return (
		<div className="AboutPage_section_tipos">
			<FadeIn className="HomePage_Fade_In">
				{tipos.map((e) => (
					<CardTipo key={e.id} e={e} />
				))}
			</FadeIn>
		</div>
	);
};

export default TiposContainer;
