import React from "react";

const Tag = ({ tag, givenClass, onRemove }) => {
	return (
		<button className={givenClass} onClick={() => onRemove(tag)}>
			#{tag}
		</button>
	);
};

export default Tag;
