import React from "react";
import PlayerAbout from "../about/PlayerAbout.js";
import TiposContainer from "./TiposContainer.js";
import FadeIn from "react-fade-in";

const ProjectInfo = () => {
	return (
		<div className="AboutPage_main_container">
			<div className="AboutPage_title">
				<div className="AboutPage_title_main">
					Banco de <span>Recursos</span>
				</div>
				<div className="AboutPage_title_separator"></div>
				<div className="AboutPage_title_name">
					Escola <span>SENAI</span> de Educação Online
				</div>
			</div>
			<div className="AboutPage_container_division">
				<div className="AboutPage_left">
					<p>
						Este banco de recursos tem o objetivo de ser uma referência sobre
						todos os conteúdos produzidos dentro da Escola SENAI de Educação
						Online, catalogando tudo que já temos produzido e organizando as
						informações para que possamos ter um controle do que já se tem
						pronto, evitando assim o retrabalho e nos proporcionando mais tempo
						para criar coisas novas.
					</p>
					<p>
						<strong>Desenvolvimento do Projeto: </strong>Núcleo de Tecnologia -
						Escola <span className="nomeSenai">Senai</span> de Educação Online.
					</p>
					<p>
						<strong>Desenvolvedor responsável: </strong>
						{/* <div id="AboutPage_Dev_Master_div">
							<div id="AboutPage_Dev_Contacts"> */}
						Wesley Silveira
						{/* <div className="AboutPage_Dev_Badges">
									<FadeIn className="HomePage_Fade_In"> */}
						<a href="https://www.linkedin.com/in/wesleyjps/">
							<img
								alt="Static Badge LinkedIn"
								src="https://img.shields.io/badge/LinkedIn-FAFAFA?style=for-the-badge&logo=LinkedIn&logoColor=0A66C2"
							/>
						</a>
						<a href="mailto:wsilveira@sp.senai.br">
							<img
								alt="Static Badge LinkedIn"
								src="https://img.shields.io/badge/Email-FAFAFA?style=for-the-badge&logo=MicrosoftOutlook&logoColor=0078D4"
							/>
						</a>
						{/* </FadeIn>
								</div> 
						</div>
						</div> */}
					</p>
					<p>
						<strong>Tecnologias utilizadas:</strong>
						<div className="AboutPage_Badges">
							<FadeIn className="HomePage_Fade_In">
								<img
									alt="Static Badge NodeJS"
									src="https://img.shields.io/badge/NodeJS-FAFAFA?style=for-the-badge&logo=Node.js&logoColor=#339933"
								/>
								<img
									alt="Static Badge ReactJS"
									src="https://img.shields.io/badge/ReactJS-FAFAFA?style=for-the-badge&logo=react&logoColor=61DAFB"
								/>
								<img
									alt="Static Badge Azure DevOps"
									src="https://img.shields.io/badge/Azure DevOps-FAFAFA?style=for-the-badge&logo=AzureDevOps&logoColor=0078D7"
								/>
								<img
									alt="Static Badge MSSQL"
									src="https://img.shields.io/badge/MSSQL-FAFAFA?style=for-the-badge&logo=microsoftsqlserver&logoColor=CC2927"
								/>

								<img
									alt="Static Badge GitHub"
									src="https://img.shields.io/badge/GitHub-FAFAFA?style=for-the-badge&logo=GitHub&logoColor=181717"
								/>
								<img
									alt="Static Badge Microsoft"
									src="https://img.shields.io/badge/Microsoft Authentication Library-FAFAFA?style=for-the-badge&logo=Microsoft&logoColor=258FFA"
								/>
							</FadeIn>
						</div>
					</p>
					<p>
						<strong>Tipos de recursos:</strong>
					</p>
				</div>

				<div className="AboutPage_right">
					<PlayerAbout />
				</div>
			</div>
			<TiposContainer />
		</div>
	);
};

export default ProjectInfo;
