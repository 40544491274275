import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../context/UserContext.js";
import ConfirmationPopup from "../components/common/ConfirmationPopup.js";
import Menu from "../components/common/Menu.js";
import Footer from "../components/common/Footer.js";
import EditForm from "../components/edit/EditForm.js";
import loader from "../assets/images/loading_blue.png";

const edit_page_style = {
	width: "100vw",
	minHeight: "80vh",
	backgroundColor: "#FAFAFA",
	padding: "5vh 5vw",
	textAlign: "center",
};

const btn_confirmar = {
	border: "none",
	backgroundColor: "#008000",
	color: "#FFFFFF",
	padding: "0.5vh 2vw",
	borderRadius: "6px",
};

const div_loader = {
	width: "100%",
	height: "85vh",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
};

const EditPage = ({ match }) => {
	const [resource, setResource] = useState(null);
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const { user } = useUser();
	const [inSearch, setInSearch] = useState(false);

	const { referencia } = useParams();

	useEffect(() => {
		const fetchResource = async () => {
			try {
				setInSearch(true);
				const response = await fetch(
					`${process.env.REACT_APP_BASE_URL}/find/resources/${user.encodedEmail}`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							params: {
								referenceNumber: referencia,
							},
						}),
					}
				);
				if (response.status === 401) {
					throw new Error("Unauthorized Access");
				}

				const resourceData = await response.json();
				setInSearch(false);
				setResource(resourceData);
			} catch (error) {
				console.error("Erro ao buscar recurso para edição:", error);
			}
		};

		fetchResource();
	}, [referencia]);

	return (
		<>
			<Menu />

			<div
				style={
					inSearch
						? { ...edit_page_style, ...{ display: "none" } }
						: { ...edit_page_style, ...{ display: "block" } }
				}
			>
				<EditForm initialValues={resource} />

				<button
					hidden
					style={btn_confirmar}
					onClick={() => setIsPopupOpen(true)}
				>
					Confirmar
				</button>

				<ConfirmationPopup
					isOpen={isPopupOpen}
					onClose={() => setIsPopupOpen(false)}
				/>
			</div>

			{inSearch && (
				<div style={div_loader}>
					<img id="loader_blue" alt="Loader" src={loader} />
				</div>
			)}
			<Footer />
		</>
	);
};

export default EditPage;
