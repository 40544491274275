import Footer from "../components/common/Footer";
import Menu from "../components/common/Menu";
import ResourceNotFound from "../components/search/ResourceNotFound";

const ResourceNotFoundPage = () => {
	return (
		<div>
			<Menu />
			<ResourceNotFound />
			<Footer />
		</div>
	);
};

export default ResourceNotFoundPage;
