import React from "react";
import Menu from "../components/common/Menu";
import SectionTipos from "../components/home/SectionTipos";
import SearchContainer from "../components/home/SearchContainer";
import Footer from "../components/common/Footer.js";

const HomePage = () => {
	return (
		<div>
			<Menu />
			<div className="HomePage_main_container">
				<SearchContainer />

				<SectionTipos />
			</div>
			<Footer />
		</div>
	);
};

export default HomePage;
