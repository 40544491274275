import fix from "../../assets/images/fix.svg";

const GeneralError = () => {
	return (
		<div className="GeneralErrorPopUp_Content">
			<h5>
				<strong>Oops! </strong> An error ocurred
			</h5>
			<div className="GeneralError_img_container">
				<img src={fix} alt="Fixing guy" />
			</div>
			<p>
				<strong>O servidor retornou um erro do tipo: </strong>"500 Internal
				Server Error"
			</p>
			<p>
				Algo está quebrado no nosso backend. Por favor nos informe qual foi a
				requisição feita quando este erro ocorreu, e nós o consertaremos o mais
				rápido possível. Nos desculpe por qualquer incoveniência causada.
			</p>
		</div>
	);
};
export default GeneralError;
