import React from "react";
import Menu from "../components/common/Menu";
import Footer from "../components/common/Footer";
import Administradores from "../components/settings/Administradores";
import Tipos from "../components/settings/Tipos";

const SettingsPage = () => {
	return (
		<div>
			<Menu />
			<div className="SettingsPage_main_container">
				<Administradores />
				<Tipos />
			</div>
			<Footer />
		</div>
	);
};

export default SettingsPage;
