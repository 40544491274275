export async function callMsGraph(accessToken) {
	const headers = new Headers();
	const bearer = `Bearer ${accessToken}`;

	headers.append("Authorization", bearer);

	const options = {
		method: "GET",
		headers: headers,
	};

	return fetch("https://graph.microsoft.com/v1.0/me", options)
		.then((response) => response.json())
		.catch((error) => console.error(error));
}

export const loginRequest = {
	scopes: ["User.Read"],
};
