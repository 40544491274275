import React from "react";

const tag_style = {
	minWidth: "3em",
	//backgroundColor:'#3b82f5',
	backgroundColor: "transparent",
	borderRadius: "3px",
	margin: "3px",
	display: "flex",
	justifyContent: "space-between",
	color: "#3B82F5",
	fontSize: "12px",
	padding: "0.1em 0.4em",
};

const button_close_style = {
	border: "none",
	backgroundColor: "transparent",
	color: "#FFFFFF",
};

const TagExhibition = ({ tag, onRemove }) => {
	return (
		<div className="tag" style={tag_style}>
			{tag}
		</div>
	);
};

export default TagExhibition;
