import React from "react";

const CardTipo = ({ e }) => {
	return (
		<div className="AboutPage_card_tipo" title={e.descricao}>
			<div
				className="AboutPage_card_icons"
				data-toggle="tooltip"
				data-placement="top"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="30"
					height="30"
					fill={e.color}
					viewBox="0 0 16 16"
				>
					<path d={e.icond1} />
					<path d={e.icond2} />
				</svg>
				<p>{e.tipo}</p>
			</div>
		</div>
	);
};

export default CardTipo;
