import React from "react";
import Sad from "../../assets/images/sad.svg";
import NotFoundImage from "../../assets/images/not-found.png";
import { Link } from "react-router-dom";
import { useUser } from "../../context/UserContext";

const style = {
	fontWeight: "800",
	marginRight: "0.5em",
};

const NotFound = () => {
	return (
		<div className="NotFound_Container">
			<div className="SearchPage_NotFound">
				<img id="notFoundImage" src={NotFoundImage} alt="not found" />
				<div className="SearchPage_NotFound_Text">
					<img src={Sad} alt="sad face" style={{ marginRight: "1em" }} />
					<span style={style}>404</span> Página não encontrada
				</div>
				<div className="RedirectInitialPage">
					Clique <Link to={"/home"}>aqui</Link> para voltar à página inicial
				</div>
			</div>
		</div>
	);
};

export default NotFound;
