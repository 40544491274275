import React from "react";
import Sad from "../../assets/images/sad.svg";
import RNFImage from "../../assets/images/ResourceNotFound.png";
import { Link } from "react-router-dom";
import { useUser } from "../../context/UserContext";

const style = {
	fontWeight: "800",
	marginRight: "0.5em",
};

const ResourceNotFound = () => {
	return (
		<div className="NotFound_Container">
			<div className="SearchPage_NotFound">
				<img id="notFoundImage" src={RNFImage} alt="not found" />
				<div className="SearchPage_NotFound_Text">
					<p>
						Oooops... O recurso que você procura não foi encontrado. Tente
						buscar novamente com outros critérios ou entre em contato com os
						desenvolvedores. Clique <Link to={"/home"}>aqui</Link> para voltar à
						página inicial
					</p>
				</div>
				<div className="RedirectInitialPage"></div>
			</div>
		</div>
	);
};

export default ResourceNotFound;
