import React, { useState, useEffect } from "react";
import Tag from "./Tag";
import { useUser } from "../../context/UserContext.js";

const Tags = ({ capturaTags, initial_tags }) => {
	const [allTags, setAllTags] = useState([]);
	const [selectedTags, setSelectedTags] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [updatedTags, setUpdatedTags] = useState(false);
	const { user } = useUser();

	useEffect(() => {
		const fetchTags = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BASE_URL}/list/tags/${user.encodedEmail}`,
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
				if (response.status === 401) {
					throw new Error("Unauthorized Access");
				}
				const data = await response.json();
				setAllTags(data);
			} catch (error) {
				console.error("Erro ao obter as tags:", error);
			}
		};

		fetchTags();
	}, []);

	const handleQuickSearch = async (event) => {
		const inputValue = event.target.value;
		setSearchTerm(inputValue);

		if (inputValue && inputValue.endsWith(" ")) {
			// Se o usuário pressionar [Espaço], verifica se a tag está na lista
			const tagToAdd = inputValue.trim();

			if (tagToAdd.includes(" ")) {
				let tagToAddNew = tagToAdd.split(" ");
				for await (let e of tagToAddNew) {
					if (allTags.some((tag) => tag.tag === e)) {
						handleTransferTag(e);
						capturaTags(selectedTags);
						setSearchTerm("");
					} else {
						setSelectedTags(selectedTags.concat(tagToAddNew));
						capturaTags(selectedTags);
						setSearchTerm("");
					}
				}
			} else {
				if (allTags.some((tag) => tag.tag === tagToAdd)) {
					handleTransferTag(tagToAdd);
					capturaTags(selectedTags);
					setSearchTerm("");
				} else {
					setSelectedTags([...selectedTags, tagToAdd]);
					capturaTags(selectedTags);
					setSearchTerm("");
				}
			}
		}
	};

	const handleRemoveTag = (tagToRemove) => {
		// Remove a tag da lista de tags selecionadas
		const updatedTags = selectedTags.filter((tag) => tag !== tagToRemove);
		setSelectedTags(updatedTags);
		capturaTags(selectedTags);
	};

	const handleTransferTag = (tagToTransfer) => {
		// Transfere a tag entre as divs
		setSelectedTags([...selectedTags, tagToTransfer]);

		// Remove a tag da lista de tags disponíveis
		const updatedTags = allTags.filter((tag) => tag.tag !== tagToTransfer);
		setAllTags(updatedTags);
		capturaTags(selectedTags);
	};

	if (!updatedTags) {
		if (initial_tags && initial_tags.length > 0) {
			setSelectedTags(selectedTags.concat(initial_tags));
			setUpdatedTags(true);
			capturaTags(selectedTags);
		}
	}

	return (
		<div className="RegisterPage_container_master_tags">
			{/* Input de quicksearch */}
			<input
				className="RegisterPage_input_search_style"
				type="text"
				value={searchTerm}
				onChange={handleQuickSearch}
				placeholder="Digite a tag desejada e pressione Espaço para adicionar"
			/>

			{/* Tags selecionadas */}
			<div className="RegisterPage_container_tags">
				selecionadas:
				{selectedTags.map((tag, index) => (
					<Tag
						givenClass="RegisterPage_Selected_Tag"
						key={index}
						tag={tag}
						onRemove={handleRemoveTag}
					/>
				))}
			</div>

			{/* Tags disponíveis como sugestão */}
			<div className="RegisterPage_container_tags">
				{allTags
					.filter((tag) => tag.tag.includes(searchTerm.trim()))
					.map((tag, index) => (
						<Tag
							key={index}
							tag={tag.tag}
							givenClass="RegisterPage_Sugested_Tag"
							onRemove={() => handleTransferTag(tag.tag)}
						/>
					))}
			</div>
		</div>
	);
};

export default Tags;
