import React, { useEffect, useState } from "react";
import { Buffer } from "buffer";
import { useNavigate } from "react-router-dom";
import TagExhibition from "./TagExhibition.js";
import { useUser } from "../../context/UserContext.js";

const ExhibitionCard = ({ resource, onClose }) => {
	const [imagemUrl, setImagemUrl] = useState("");
	const navigate = useNavigate();
	const { user } = useUser();

	var formatacao = new Intl.DateTimeFormat("pt-BR", {
		dateStyle: "full",
		timeStyle: "long",
		timeZone: "America/Sao_Paulo",
	});

	useEffect(() => {
		try {
			const fetchImagem = async () => {
				try {
					let i = Buffer.from(resource.imagem ?? "", "base64");
					setImagemUrl(i);
				} catch (error) {
					console.log(error.message);
				}
			};

			fetchImagem();
		} catch (error) {
			console.log(error.message);
			navigate("/home");
		}
	}, [resource]);

	const handleDelete = async (id, e) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BASE_URL}/delete/resource/${user.encodedEmail}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ id: id }),
				}
			);
			if (response.status === 401) {
				throw new Error("Unauthorized Access");
			}
			onClose(e, id, true);
			// console.log(response);
		} catch (error) {
			console.log(error.message);
			navigate("/home");
		}
	};

	const handleEdit = async (ref, e) => {
		navigate(`/edit/${ref}`);
	};

	const data_criacao = resource.createdAt
		? formatacao.format(new Date(resource.createdAt))
		: formatacao.format(new Date(Date.now()));
	const data_alteracao = resource.updatedAt
		? formatacao.format(new Date(resource.updatedAt))
		: formatacao.format(new Date(Date.now()));

	return (
		<div className="ExhibitionCard_container_exibhition_card">
			<div className="ExhibitionCard_div_esquerda_exhibition_card">
				<div
					style={{
						color: resource.ntec_catalogo_tipo?.color,
						fontFamily: "'Roboto', sans-serif",
						fontWeight: "bold",
						fontSize: "20px",
						marginBottom: "1em",
					}}
				>
					{resource.ntec_catalogo_tipo?.tipo}
				</div>
				<div className="ExhibitionCard_container_image">
					{imagemUrl ? (
						<img src={`data:image/png;base64,${imagemUrl}`} alt="Imagem"></img>
					) : (
						<p>Carregando imagem...</p>
					)}
				</div>
				<div className="ExhibitionCard_descricao_exhibition_card">
					{resource.descricao}
				</div>
			</div>
			<div className="ExhibitionCard_div_direita_exhibition_card">
				<div className="ExhibitionCard_referencia_exhibition_card">
					<span># </span>
					{resource.referencia ?? ""}
				</div>
				<div className="ExhibitionCard_observacao_exhibition_card">
					<div className="ExhibitionCard_origem_exhibition_card">
						<span>Origem: </span>
						{resource.ntec_catalogo_origem?.origem}
					</div>
					{resource.observacao ?? ""}
				</div>
				<div className="ExhibitionCard_container_tags">
					<span>Tags: </span>
					{resource.ntec_catalogo_tags?.map((e) => (
						<TagExhibition tag={e.tag} />
					))}
				</div>
				<div className="ExhibitionCard_projetos_exhibition_card">
					<span>Projetos: </span>
					{resource.ntec_catalogo_projetos?.map((e) =>
						e.id !== 70 ? (
							<TagExhibition tag={`${e.codigo} - ${e.nome}`} />
						) : (
							<></>
						)
					)}
				</div>
				<div className="ExhibitionCard_proj_original_exhibition_card"></div>

				{/* <div
					style={{
						fontWeight: "bold",
						marginRight: "3px",
						textAlign: "center",
					}}
				>
					Links:
				</div> */}
				<div className="ExhibitionCard_link_exhibition_card">
					<span>Rede:</span> {resource.rede ?? ""}
				</div>
				<div className="ExhibitionCard_link_exhibition_card">
					<span>Moodle:</span> {resource.moodle ?? ""}
				</div>
				<div className="ExhibitionCard_link_exhibition_card">
					<span>Youtube:</span> {resource.youtube ?? ""}
				</div>
				<div className="ExhibitionCard_link_exhibition_card">
					<span>One Drive:</span> {resource["one drive"]}
				</div>
				<div className="ExhibitionCard_info_exhibition_card">
					<span>Criação:</span>
					{data_criacao}
					<br />
					<span>Última alteração:</span>
					{data_alteracao}
				</div>
				<div className="ExhibitionCard_botoes_exhibition_card">
					<button onClick={(e) => handleEdit(resource.referencia, e)}>
						Editar
					</button>
					<button onClick={(e) => handleDelete(resource.id, e)}>Apagar</button>
				</div>
			</div>
		</div>
	);
};

export default ExhibitionCard;
