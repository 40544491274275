import React, { useCallback, useEffect, useRef, useState } from "react";
import SearchResults from "../components/search/SearchResults.js";
import Menu from "../components/common/Menu.js";
import Footer from "../components/common/Footer.js";
import { useUser } from "../context/UserContext.js";
import loader from "../assets/images/loading_blue.png";
import { useNavigate } from "react-router-dom";

const MyRecordsPage = () => {
	const [results, setResults] = useState([]);
	const [page, setPage] = useState(1);
	const [hasMore, setHasMore] = useState(true);
	const { user } = useUser();
	const loaderRef = useRef(null);
	const [initialSearchDone, setInitialSearchDone] = useState(false);
	const [inSearch, setInSearch] = useState(false);
	const [actualPage, setActualPage] = useState("");
	const navigate = useNavigate();

	const handleSearch = useCallback(
		async (params, nextPage) => {
			try {
				if (hasMore) {
					setInSearch(true);
					const response = await fetch(
						`${process.env.REACT_APP_BASE_URL}/find/my_Resources/${user.encodedEmail}`,
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify({ ...params, page, user }),
						}
					);
					const data = await response.json();

					if (
						data.resultados &&
						results.length + data.resultados.length < data.totalItens
					) {
						setResults((prevResults) => [...prevResults, ...data.resultados]);
						setPage((prevPage) => prevPage + 1);
						setInSearch(false);
						setHasMore(page < data.totalPaginas);
						let quantity =
							data.paginaAtual === 1
								? data.resultados.length
								: results.length + data.resultados.length;
						if (data.paginaAtual && data.totalPaginas) {
							setActualPage(`Mostrando ${quantity} de ${data.totalItens}`);
						}
						setInitialSearchDone(true);
					} else {
						setHasMore(false);
					}
				} else {
					return;
				}
			} catch (error) {
				navigate("/notfound");
			}
		},
		[page]
	);

	const handleInitialSearch = useCallback(async () => {
		try {
			if (page === 1) {
				setInSearch(true);
				try {
					const response = await fetch(
						`${process.env.REACT_APP_BASE_URL}/find/my_Resources/${user.encodedEmail}`,
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify({ page, user }),
						}
					);
					if (!response.ok) navigate("/notfound");

					const data = await response.json();
					setInSearch(false);

					if (data.resultados) {
						setHasMore(true);
						setPage((prevPage) => prevPage + 1);
						setResults(data.resultados);
						setInitialSearchDone(true);
						let quantity =
							data.paginaAtual === 1
								? data.resultados.length
								: results.length + data.resultados.length;
						setActualPage(`Mostrando ${quantity} de ${data.totalItens}`);
					} else {
						setHasMore(false);
					}
				} catch (error) {
					console.log(error);
					navigate("/notfound");
				}
			}
		} catch (error) {
			navigate("/notfound");
		}
	}, [handleSearch]);

	const handleObserver = useCallback(
		(entries) => {
			const target = entries[0];
			if (target.isIntersecting && hasMore) {
				handleSearch({}, page);
			}
		},
		[handleSearch, page, hasMore]
	);

	useEffect(() => {
		if (initialSearchDone) {
			const options = {
				root: null,
				rootMargin: "20px",
				threshold: 1.0,
			};
			const observer = new IntersectionObserver(handleObserver, options);
			if (loaderRef.current) observer.observe(loaderRef.current);

			return () => {
				if (loaderRef.current) observer.unobserve(loaderRef.current);
			};
		}
	}, [handleObserver, initialSearchDone]);

	useEffect(() => {
		handleInitialSearch();
	}, [handleInitialSearch]);

	return (
		<div>
			<Menu />
			<div
				id="MyRecordsPage_main_container"
				className="MyRecordsPage_main_container"
			>
				<SearchResults results={results} />
				{inSearch && (
					<div>
						<img id="loader_blue" alt="Loader" src={loader} />
					</div>
				)}
				{hasMore && <div ref={loaderRef}></div>}
				{actualPage && (
					<div className="SearchPage_actualPage">{actualPage}</div>
				)}
			</div>

			<Footer />
		</div>
	);
};

export default MyRecordsPage;
