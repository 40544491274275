import { useEffect, useState, useCallback } from "react";
import React from "react";
import Administrador from "./Administrador.js";
import Add from "../../assets/images/add.svg";
import { useUser } from "../../context/UserContext.js";

const Administradores = () => {
	const [users, setUsers] = useState([]);
	const [levels, setLevels] = useState([]);
	const nullUser = {
		nome: "",
		email: "",
		ntecCatalogoAcessoId: 0,
		newUser: true,
	};
	const { user } = useUser();

	const setAdms = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BASE_URL}/list/users/${user.encodedEmail}`,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			if (response.status === 401) {
				throw new Error("Unauthorized Access");
			}
			const listOfUsers = await response.json();
			setUsers(listOfUsers);
		} catch (error) {
			console.error(`Erro ao buscar lista de usuários`, error);
		}
	};

	useEffect(() => {
		setAdms();
	}, []);

	useEffect(() => {
		const setAccess = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BASE_URL}/list/levels/${user.encodedEmail}`,
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
				if (response.status === 401) {
					throw new Error("Unauthorized Access");
				}

				const levelsOfAccess = await response.json();
				setLevels(levelsOfAccess);
			} catch (error) {
				console.error("Erro ao buscar os níveis de acesso", error);
			}
		};
		setAccess();
	}, []);

	const handleUserUpdate = useCallback(() => {
		setAdms();
	}, []);

	const handleAddClick = () => {
		setUsers([...users, nullUser]);
	};

	return (
		<div className="SettingsPage_container_admins">
			{users &&
				users.map((e) => (
					<Administrador
						key={e.id}
						user={e}
						levels={levels}
						onUserUpdate={handleUserUpdate}
					/>
				))}

			<button id="SettingsPage_btn_Administradores" onClick={handleAddClick}>
				<img src={Add} alt="Add Pearson Icon" />
				Adicionar
			</button>
		</div>
	);
};

export default Administradores;
