import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo-ead.svg";
import menuIcon from "../../assets/images/menu.svg";
import { useUser } from "../../context/UserContext.js";
import "./Menu.css";

const Menu = () => {
	const { user, setUser } = useUser();
	const navigate = useNavigate();
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const handleLogout = () => {
		setUser(null);
		navigate("/");
	};

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	return (
		<div className={`menu-container ${isMenuOpen ? "menu-open" : ""}`}>
			<button onClick={toggleMenu} className="menu-button">
				<img src={menuIcon} alt="ícone menu" />
			</button>
			<nav className="menu-options">
				<img src={Logo} alt="Logo Escola Senai de Educação Online" />
				<Link to={user ? "/home" : "/"}>Início</Link>
				{user && (
					<>
						<Link to="/search">Buscar</Link>
						{(user.role === "ADMINISTRADOR" || user.role === "EDITOR") && (
							<>
								<Link to="/register">Cadastrar</Link>
								<Link to="/myrecords">Meus Cadastros</Link>
							</>
						)}
						{user.role === "ADMINISTRADOR" && (
							<>
								<Link to="/deletedresources">Recursos Apagados</Link>
								<Link to="/settings">Configurações</Link>
							</>
						)}
					</>
				)}
				<Link to="/about">Sobre</Link>
			</nav>

			<div className="user-greeting">
				<p>
					{user
						? `${user.name.split(" ")[0]} ${
								user.name.split(" ")[user.name.split(" ").length - 1]
						  }`
						: ""}
				</p>
				{user && (
					<button
						className={`${isMenuOpen ? "opened" : "closed"}`}
						onClick={handleLogout}
					>
						Sair
					</button>
				)}
			</div>
		</div>
	);
};

export default Menu;
