import React, { useState, useEffect } from "react";
import Tags from "../common/Tags";
import ExhibitionCard from "../exhibition/ExhibitionCard";
import Close from "../../assets/images/close.svg";
import { useUser } from "../../context/UserContext.js";
import ErrorPopUp from "./ErrorPopUp.js";
import loader from "../../assets/images/loading_blue.png";
import { useNavigate } from "react-router-dom";

const RegisterForm = ({ initialValues, onConfirm }) => {
	const [image, setImage] = useState(null);
	const [description, setDescription] = useState("");
	const [link_onedrive, setlink_onedrive] = useState("");
	const [link_rede, setlink_rede] = useState("");
	const [link_YouTube, setlink_YouTube] = useState("");
	const [link_Moodle, setlink_Moodle] = useState("");
	// const [mainProject, setMainProject] = useState("");
	const [secondaryProjects, setSecondaryProjects] = useState([]);
	const [resourceOrigin, setResourceOrigin] = useState("");
	const [observations, setObservations] = useState("");
	const [resourceType, setResourceType] = useState("");
	const [tags, setTags] = useState([]);
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [errorPopUp, setErrorPopUp] = useState(false);
	const [resourceTypeOptions, setResourceTypeOptions] = useState([]);
	// const [mainProjectOptions, setMainProjectOptions] = useState([]);
	const [secondaryProjectOptions, setSecondaryProjectOptions] = useState([]);
	const [resourceOriginOptions, setResourceOriginOptions] = useState([]);
	const [createdResource, setCreatedResource] = useState({});
	const { user } = useUser();
	const [sentImage, setSentImage] = useState("");
	const [waitingFor, setWaitingFor] = useState(false);
	const navigate = useNavigate();

	const capturaTags = (childData) => {
		setTags(childData);
	};

	useEffect(() => {
		const fetchTypeOptions = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BASE_URL}/list/types/${user.encodedEmail}`,
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
				if (response.status === 401) {
					throw new Error("Unauthorized Access");
				}

				const typesOfProject = await response.json();
				setResourceTypeOptions(typesOfProject);
			} catch (error) {
				console.error("Erro ao buscar os tipos", error);
			}
		};

		const fetchMainProjectOptions = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BASE_URL}/list/projects/${user.encodedEmail}`,
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
				if (response.status === 401) {
					throw new Error("Unauthorized Access");
				}

				const mainProjectsData = await response.json();
				// setMainProjectOptions(mainProjectsData);
				setSecondaryProjectOptions(mainProjectsData);
			} catch (error) {
				console.error("Erro ao buscar opções de projetos principais:", error);
			}
		};

		const fetchResourceOriginOptions = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BASE_URL}/list/origin/${user.encodedEmail}`,
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
				if (response.status === 401) {
					throw new Error("Unauthorized Access");
				}

				const resourceOriginsData = await response.json();
				setResourceOriginOptions(resourceOriginsData);
			} catch (error) {
				console.error("Erro ao buscar opções de origem do recurso:", error);
			}
		};

		fetchMainProjectOptions();
		fetchResourceOriginOptions();
		fetchTypeOptions();
	}, []);

	const handleClosePopup = (e) => {
		e.stopPropagation();
		setIsPopupOpen(false);
	};

	const handleCloseErrorPopUp = (e) => {
		e.stopPropagation();
		setErrorPopUp(false);
		const btn = document.getElementById("RegisterPage_btn_confirm");
		btn.disabled = false;
	};

	useEffect(() => {
		if (initialValues) {
			setImage(initialValues.imagem || null);
			setResourceType(initialValues.ntec_catalogo_tipo?.tipo || "");
			setDescription(initialValues.descricao || "");
			setlink_onedrive(initialValues["one drive"] || "");
			setlink_rede(initialValues.rede || "");
			setlink_YouTube(initialValues.youtube || "");
			setlink_Moodle(initialValues.moodle || "");
			// setMainProject(initialValues.ntec_catalogo_tipo?.tipo || "");
			setSecondaryProjects(initialValues.secondaryProjects || []);
			setResourceOrigin(initialValues.ntec_catalogo_origem?.origem || "");
			setObservations(initialValues.observacao || "");
		}
	}, [initialValues]);

	const handleImageUpload = (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				const img = new Image();

				img.onload = () => {
					//const maxSizeInBytes = 5 * 1024 * 1024;
					const canvas = document.createElement("canvas");
					const ctx = canvas.getContext("2d");
					canvas.width = img.width;
					canvas.height = img.height;
					ctx.drawImage(img, 0, 0);
					// const marcaDagua = new Image();
					// marcaDagua.src = "/marca-dagua.png";
					// ctx.drawImage(marcaDagua, 0, 0);

					const processedImage = canvas.toDataURL("image/jpeg");
					setImage(processedImage);
					setSentImage(file);
				};

				img.src = reader.result;
			};
		}
	};

	const validateForm = (obj) => {
		let valid = true;
		Object.keys(obj).map(function (key, value) {
			if (typeof obj[key] === "object" && key !== "links") {
				if (obj[key].length === 0) {
					valid = false;
				}
			} else if (key !== "links") {
				if (obj[key] === "") {
					valid = false;
				}
			}
			// else if (key === "links" && obj[key] !== "") {
			// 	if (!obj[key].startsWith("https://")) {
			// 		valid = false;
			// 	}
			// }

			return obj[key];
		});

		return valid;
	};

	const setLink = (e) => {
		//console.log(e);
		if (e.target.id === "linkRede") {
			e.target.className = "rightLink";

			switch (e.target.name) {
				case "Rede":
					setlink_rede(e.target.value);
					break;
				default:
					break;
			}
		} else {
			if (e.target.value.startsWith("https://") || e.target.value === "") {
				e.target.className = "rightLink";
				e.target.labels[0].children[2].hidden = true;
				e.target.labels[0].children[3].hidden = true;
			} else {
				e.target.className = "wrongLink";

				e.target.labels[0].children[2].hidden = false;
				e.target.labels[0].children[3].hidden = false;
			}

			switch (e.target.name) {
				case "OneDrive":
					setlink_onedrive(e.target.value);
					break;
				case "Rede":
					setlink_rede(e.target.value);
					break;
				case "YouTube":
					setlink_YouTube(e.target.value);
					break;
				case "Moodle":
					setlink_Moodle(e.target.value);
					break;

				default:
					break;
			}
		}
	};

	const handleSubmit = () => {
		try {
			let dataToSend = new FormData();

			let recurso = {
				tags: tags,
				description: description,
				links: {
					one_drive: link_onedrive,
					rede: link_rede,
					youtube: link_YouTube,
					moodle: link_Moodle,
				},

				resourceType: resourceType,
				mainProject: 70,
				secondaryProjects: secondaryProjects,
				resourceOrigin: resourceOrigin,
				observations: observations,
				user: user,
			};

			if (sentImage !== "" && validateForm(recurso)) {
				setWaitingFor(true);
				dataToSend.append("imagem", sentImage);
				dataToSend.append("recurso", JSON.stringify(recurso));
				fetch(
					`${process.env.REACT_APP_BASE_URL}/create/resource/${user.encodedEmail}`,
					{
						method: "POST",
						headers: {
							//"Content-Type": "multipart/form-data",
						},
						body: dataToSend,
					}
				)
					.then((response) => response.json())
					.then((data) => {
						setCreatedResource(data);
						setWaitingFor(false);
						setIsPopupOpen(true);
					})
					.catch((error) => {
						console.error("Erro ao realizar o cadastro:", error);
						navigate("/error");
					});
			} else {
				setErrorPopUp(true);
				const btn = document.getElementById("RegisterPage_btn_confirm");
				btn.disabled = true;
			}
		} catch (error) {
			console.error("Erro ao realizar o cadastro:", error);
			setErrorPopUp(true);
			const btn = document.getElementById("RegisterPage_btn_confirm");
			btn.disabled = true;
			navigate("/error");
		}
	};

	return (
		<div className="RegisterPage_container_form">
			<div className="RegisterPage_container_image">
				<div>
					<select
						value={resourceType}
						onChange={(el) => setResourceType(el.target.value)}
						className="RegisterForm_select"
					>
						<option value="" disabled>
							Selecione o tipo do recurso
						</option>
						{resourceTypeOptions.map((e) => (
							<option key={e.id} value={e.id} style={{ color: e.color }}>
								{e.tipo}
							</option>
						))}
					</select>
				</div>
				{!image && (
					<input
						className="RegisterPage_Input_Image"
						type="file"
						accept="image/*"
						onChange={handleImageUpload}
					/>
				)}
				{image && (
					<img
						className="RegisterPage_image_loaded"
						src={image}
						alt="Imagem com marca d'água"
					/>
				)}
				<textarea
					className="RegisterPage_description"
					placeholder="Insira aqui a descrição do conteúdo (até 500 caracteres)"
					maxLength={500}
					value={description}
					rows={5}
					onChange={(e) => setDescription(e.target.value)}
				/>
			</div>
			<div className="RegisterPage_container_informations">
				<Tags capturaTags={capturaTags} />
				<div className="RegisterPage_container_links">
					<div className="RegisterPage_input_wrap">
						<label for="linkOneDrive">
							<img
								alt="Static Badge OneDrive"
								src="https://img.shields.io/badge/-FFFFFF?style=for-the-badge&logo=MicrosoftOneDrive&logoColor=0078D4"
							/>
							<span className="span1">Link OneDrive:</span>
							<span className="span2" id="span2_OneDrive" hidden>
								Exemplo:{" "}
							</span>
							<span className="span3" id="span3_OneDrive" hidden>
								https://1drv.ms
							</span>
						</label>
						<input
							id="linkOneDrive"
							type="text"
							placeholder="Link OneDrive"
							value={link_onedrive}
							name="OneDrive"
							onChange={(e) => setLink(e)}
						/>
					</div>
					<div className="RegisterPage_input_wrap">
						<label for="linkRede">
							<img
								alt="Static Badge Local Databank"
								src="https://img.shields.io/badge/-FFFFFF?style=for-the-badge&logo=Adminer&logoColor=34567C"
							/>
							<span className="span1">Link rede:</span>
						</label>
						<input
							id="linkRede"
							type="text"
							placeholder="Link Rede"
							name="Rede"
							value={link_rede}
							onChange={(e) => setLink(e)}
						/>
					</div>

					<div className="RegisterPage_input_wrap">
						<label for="link_YouTube">
							<img
								alt="Static Badge Youtube"
								src="https://img.shields.io/badge/-FFFFFF?style=for-the-badge&logo=YouTube&logoColor=FF0000"
							/>
							<span className="span1">Link YouTube:</span>
							<span className="span2" id="span2_YouTube" hidden>
								Exemplo:{" "}
							</span>
							<span className="span3" id="span3_YouTube" hidden>
								https://youtu.be/3vYs
							</span>
						</label>
						<input
							type="text"
							placeholder="Link Youtube"
							id="link_YouTube"
							name="YouTube"
							value={link_YouTube}
							onChange={(e) => setLink(e)}
						/>
					</div>
					<div className="RegisterPage_input_wrap">
						<label for="link_Moodle">
							<img
								alt="Static Badge Moodle"
								src="https://img.shields.io/badge/-FFFFFF?style=for-the-badge&logo=Moodle&logoColor=#F98012"
							/>
							<span className="span1">Link Moodle:</span>
							<span className="span2" id="span2_Moodle" hidden>
								Exemplo:{" "}
							</span>
							<span className="span3" id="span3_Moodle" hidden>
								https://platf.sp.senai.br/my/
							</span>
						</label>
						<input
							type="text"
							id="link_Moodle"
							placeholder="Link Moodle"
							name="Moodle"
							value={link_Moodle}
							onChange={(e) => setLink(e)}
						/>
					</div>
				</div>
				<span className="label_origin">
					Selecione os projetos onde o recurso foi utilizado:
				</span>
				<div className="RegisterPage_container_projects">
					{/* <select
						value={mainProject}
						onChange={(e) => setMainProject(e.target.value)}
					>
						<option value="">Selecione o projeto principal</option>
						{mainProjectOptions.map((project) => (
							<option key={project.id} value={project.id}>
								{project.nome}
							</option>
						))}
					</select> */}
					<select
						multiple
						value={secondaryProjects}
						onChange={(e) =>
							setSecondaryProjects(
								Array.from(e.target.selectedOptions, (option) => option.value)
							)
						}
					>
						<option value="" disabled>
							Projetos:
						</option>
						{secondaryProjectOptions.map((project) => (
							<option key={project.id} value={project.id}>
								{project.nome}
							</option>
						))}
					</select>
				</div>
				<span className="label_origin">Selecione a origem do recurso</span>
				<div className="RegisterPage_container_origin">
					<select
						value={resourceOrigin}
						onChange={(e) => setResourceOrigin(e.target.value)}
						name="resourceOrigin"
					>
						<option value="" disabled>
							Origem:
						</option>
						{resourceOriginOptions.map((origin) => (
							<option key={origin.id} value={origin.id}>
								{origin.origem}
							</option>
						))}
					</select>
					<textarea
						rows="5"
						placeholder="Observações (até 350 caracteres)"
						maxLength={350}
						value={observations}
						onChange={(e) => setObservations(e.target.value)}
					/>
				</div>

				<button
					id="btn_formulario"
					style={{ display: "none" }}
					onClick={handleSubmit}
				>
					Confirmar Cadastro
				</button>
			</div>
			{isPopupOpen && (
				<div className="Exhibition_popup_container">
					<div className="Exhibition_popup_container_inner">
						<div
							style={{
								display: "flex",
								marginBottom: "1em",
								justifyContent: "space-between",
								borderBottom: "1px solid #00000010",
								paddingBottom: "1em",
							}}
						>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									marginRight: "1em",
									width: "90%",
								}}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="40"
									height="40"
									fill="#02C028"
									class="bi bi-clipboard2-check"
									viewBox="0 0 16 16"
								>
									<path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5z" />
									<path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5z" />
									<path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z" />
								</svg>
								<div className="RegisterPage_Text1">
									Recurso criado com sucesso!
								</div>
							</div>

							<div
								className="Exhibition_popup_container_close"
								onClick={handleClosePopup}
							>
								<img src={Close} alt="Fechar" />
							</div>
						</div>
						<div className="container_exhibition_created">
							<ExhibitionCard resource={createdResource} />
						</div>
					</div>
				</div>
			)}
			{errorPopUp && (
				<div className="Error_PopUp_Container">
					<div
						className="Exhibition_popup_container_close"
						onClick={handleCloseErrorPopUp}
					>
						<img src={Close} alt="Fechar" />
					</div>
					<ErrorPopUp />
				</div>
			)}
			{waitingFor && (
				<div className="waitingFor">
					<img id="loader_blue" alt="Loader" src={loader} />
					{/* <div className="waitingFor_text">Cadastrando...</div> */}
				</div>
			)}
		</div>
	);
};

export default RegisterForm;
