import Footer from "../components/common/Footer";
import Menu from "../components/common/Menu";
import NotFound from "../components/common/NotFound";

const NotFoundPage = () => {
	return (
		<div>
			<Menu />
			<NotFound />
			<Footer />
		</div>
	);
};

export default NotFoundPage;
