import React from "react";

const PopUpErrorLogin = ({ erro, isOpen, onClose }) => {
	if (!isOpen) {
		return null;
	}
	return (
		<div className="PopUpErrorLogin_container">
			<div className="PopUpErrorLogin_main">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="35"
					height="35"
					fill="currentColor"
					class="bi bi-emoji-frown"
					viewBox="0 0 16 16"
				>
					<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
					<path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.5 3.5 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.5 4.5 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5" />
				</svg>
				Desculpe, algo deu errado!
			</div>
			<div className="PopUpErrorLogin_text">
				Pedimos desculpas pelo transtorno, mas o seu login não foi permitido.
			</div>
			<div className="PopUpErrorLogin_Dev_Info">Error type</div>
			<div className="PopUpErrorLogin_erro">{erro}</div>
			<button onClick={onClose}>Fechar</button>
		</div>
	);
};

export default PopUpErrorLogin;
