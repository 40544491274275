import React, { useState, useEffect } from "react";
import ExhibitionCard from "../exhibition/ExhibitionCard.js";
import Close from "../../assets/images/close.svg";
import { Buffer } from "buffer";

const SearchResultCard = ({ identification, result, onRemove }) => {
	const [imagemUrl, setImagemUrl] = useState("");
	const [isPopupOpen, setIsPopupOpen] = useState(false);

	useEffect(() => {
		const fetchImagem = async () => {
			let i = Buffer.from(result.imagem ?? "", "base64");
			setImagemUrl(i);
		};

		fetchImagem();
	}, [result]);

	const handleCardClick = () => {
		const mainDiv =
			document.getElementById("SearchPage_main_container") ??
			document.getElementById("MyRecordsPage_main_container");
		mainDiv.style.position = "fixed";
		setIsPopupOpen(true);
	};

	const handleClosePopup = (e, id, remove) => {
		e.stopPropagation();
		const mainDiv =
			document.getElementById("SearchPage_main_container") ??
			document.getElementById("MyRecordsPage_main_container");
		mainDiv.style.position = "unset";
		setIsPopupOpen(false);
		if (remove && id) {
			onRemove(id);
		}
	};

	return (
		<div
			id={identification}
			className="SearchPage_card_result"
			onClick={handleCardClick}
		>
			<div className="SearchPage_card_img">
				{imagemUrl ? (
					<img src={`data:image/png;base64,${imagemUrl}`} alt="Imagem"></img>
				) : (
					<p>Carregando imagem...</p>
				)}
			</div>
			<div className="SearchPage_card_type">
				<div
					style={{
						textTransform: "uppercase",
						fontWeight: "bold",
						color: result?.ntec_catalogo_tipo?.color,
						fontSize: "20px",
					}}
				>
					{result?.ntec_catalogo_tipo?.tipo}
				</div>
				<div className="SearchPage_card_reference">#{result?.referencia}</div>
				<div className="SearchPage_card_description">{result?.descricao}</div>
			</div>

			{isPopupOpen && (
				<div className="Exhibition_popup_container">
					<div className="Exhibition_popup_container_inner">
						<div
							className="Exhibition_popup_container_close"
							onClick={handleClosePopup}
						>
							<img src={Close} alt="Fechar" />
						</div>
						<ExhibitionCard resource={result} onClose={handleClosePopup} />
					</div>
				</div>
			)}
		</div>
	);
};

export default SearchResultCard;
