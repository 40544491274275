import React, { useState } from "react";
import Menu from "../components/common/Menu";
import RegisterForm from "../components/register/RegisterForm";
import ConfirmationPopup from "../components/common/ConfirmationPopup.js";
import Footer from "../components/common/Footer.js";

const RegisterPage = () => {
	const [isPopupOpen, setIsPopupOpen] = useState(false);

	const handleConfirmClick = () => {
		// Fechar a pop-up após a confirmação
		setIsPopupOpen(false);
		document.getElementById("btn_formulario").click();
	};

	return (
		<div>
			<Menu />
			<div className="RegisterPage_main_container">
				<RegisterForm onConfirm={handleConfirmClick} />
				<div className="RegisterPage_div_btn">
					<button
						id="RegisterPage_btn_confirm"
						className="RegisterPage_btn_confirm"
						onClick={() => setIsPopupOpen(true)}
					>
						Confirmar
					</button>
				</div>

				<ConfirmationPopup
					isOpen={isPopupOpen}
					onClose={() => setIsPopupOpen(false)}
					onConfirm={handleConfirmClick}
				/>
			</div>
			<Footer />
		</div>
	);
};

export default RegisterPage;
