import React from "react";

const player_style = {
	width: "100%",
	height: "100%",
	display: "flex",
	justifyContent: "center",
	alignItems: "start",
};

const video_style = {
	borderRadius: "7px",
};

const PlayerAbout = () => {
	return (
		<div style={player_style}>
			<iframe
				style={video_style}
				width="560"
				height="315"
				src={process.env.REACT_APP_WELCOME_VIDEO_URL}
				title="YouTube video player"
				frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				allowfullscreen
			></iframe>
		</div>
	);
};

export default PlayerAbout;
