import React, { useState, useEffect, useRef } from "react";
import Delete from "../../assets/images/delete.svg";
import Edit from "../../assets/images/edit.svg";

function Administrador({ user, levels, onUserUpdate }) {
	const [isEditing, setIsEditing] = useState(false);
	const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
	const [editedUser, setEditedUser] = useState({
		nome: user.nome,
		email: user.email,
		ntecCatalogoAcessoId: user.ntecCatalogoAcessoId,
	});
	const adminRef = useRef(null);

	useEffect(() => {
		if (user.hasOwnProperty("newUser")) {
			setIsEditing(true);
		}
	}, []);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				isEditing &&
				adminRef.current &&
				!adminRef.current.contains(event.target)
			) {
				setIsEditing(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isEditing]);

	const handleEditClick = () => {
		setIsEditing(true);
	};

	const handleEditConfirm = async () => {
		try {
			if (user.hasOwnProperty("newUser")) {
				const response = await fetch(
					`${process.env.REACT_APP_BASE_URL}/create/user/${user.encodedEmail}`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({ ...user, ...editedUser }),
					}
				);
				if (response.status === 401) {
					throw new Error("Unauthorized Access");
				}
			} else {
				const response = await fetch(
					`${process.env.REACT_APP_BASE_URL}/update/user/${user.encodedEmail}`,
					{
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({ ...user, ...editedUser }),
					}
				);
				if (response.status === 401) {
					throw new Error("Unauthorized Access");
				}
			}

			onUserUpdate();
		} catch (error) {
			console.error("Error during fetch:", error);
		}
		setIsEditing(false);
	};

	const handleDeleteConfirm = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BASE_URL}/delete/user/${user.encodedEmail}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(user),
				}
			);
			if (response.status === 401) {
				throw new Error("Unauthorized Access");
			}

			onUserUpdate();
		} catch (error) {
			console.error("Error during fetch:", error);
		}
		setIsConfirmingDelete(false);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setEditedUser((prev) => ({ ...prev, [name]: value }));
	};

	const handleDeleteClick = () => {
		setIsConfirmingDelete(true);
	};

	return (
		<div className="SettingsPage_admin_line" ref={adminRef}>
			{isEditing ? (
				<>
					<input
						type="text"
						name="nome"
						value={editedUser.nome}
						onChange={handleInputChange}
					/>
					<input
						type="text"
						name="email"
						value={editedUser.email}
						onChange={handleInputChange}
					/>
					<select
						name="ntecCatalogoAcessoId"
						value={editedUser.ntecCatalogoAcessoId}
						onChange={handleInputChange}
					>
						{levels.map((acesso) => (
							<option key={acesso.id} value={acesso.id}>
								{acesso.nivel}
							</option>
						))}
					</select>
					<button type="button" onClick={handleEditConfirm}>
						Confirmar
					</button>
				</>
			) : (
				<>
					<div className="SettingsPage_admin_column">{user.nome}</div>
					<div className="SettingsPage_admin_column">{user.email}</div>
					<div className="SettingsPage_admin_column">
						{user.ntec_catalogo_acesso?.nivel}
					</div>
					<div className="SettingsPage_admin_column">
						<button
							id="SettingsPage_btn_Edit"
							type="button"
							onClick={handleEditClick}
						>
							<img src={Edit} alt="Editar imagem" />
							Editar
						</button>
						<button
							id="SettingsPage_btn_Delete"
							type="button"
							onClick={handleDeleteClick}
						>
							<img src={Delete} alt="Apagar imagem" />
							Remover
						</button>
					</div>
				</>
			)}

			{isConfirmingDelete && (
				<div>
					<p>Tem certeza de que deseja excluir este usuário?</p>
					<button type="button" onClick={handleDeleteConfirm}>
						Sim
					</button>
					<button type="button" onClick={() => setIsConfirmingDelete(false)}>
						Não
					</button>
				</div>
			)}
		</div>
	);
}

export default Administrador;
