import React from "react";

const footer_container = {
	width: "100vw",
	height: "10vh",
	backgroundColor: "#3b82f5",
	display: "flex",
	justifyContent: "space-between",
	flexDirection: "column",
	alignItems: "center",
};

const footer_linha = {
	width: "100vw",
	height: "1vh",
	borderBottom: "1px solid #FFFFFF",
};

const footer_text = {
	marginBottom: "1vh",
	color: "#FFFFFF",
	fontFamily: "'Roboto', sans-serif",
	fontSize: "12px",
};

const Footer = () => {
	return (
		<div style={footer_container}>
			<div style={footer_linha}></div>
			<div style={footer_text}>
				{new Date(Date.now()).getFullYear()} - NTEC - Núcleo de Tecnologia
				<br />
				Escola SENAI de Educação Online
			</div>
		</div>
	);
};

export default Footer;
