import React from "react";
import { Link } from "react-router-dom";

const CardTipo = ({ e }) => {
	return (
		<div className="HomePage_container_icons">
			<Link to="/search">
				<div
					className="HomePage_card_icons"
					data-toggle="tooltip"
					data-placement="top"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="30"
						height="30"
						fill={e.color}
						viewBox="0 0 16 16"
					>
						<path d={e.icond1} />
						<path d={e.icond2} />
					</svg>
					<div
						className="HomePage_cardTipo_Tipo"
						style={{ textDecoration: "none", marginTop: "5px" }}
					>
						{e.tipo}
					</div>
				</div>
			</Link>
		</div>
	);
};

export default CardTipo;
