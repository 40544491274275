import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import banner from "../../assets/images/banner.svg";
import Search from "./Search";

const SearchContainer = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const navigate = useNavigate();

	const handleSearch = () => {
		// Redirecione para a página de busca com a palavra inserida no campo
		navigate(`/search?term=${searchTerm}`);
	};

	return (
		<div
			className="HomePage_search_container"
			// style={{ backgroundImage: `url(${banner})` }}
		>
			<div className="HomePage_title">Descubra, conheça e explore</div>
			<div className="HomePage_div_input" hidden>
				<input
					type="text"
					placeholder="Digite uma palavra..."
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
				/>
				<button onClick={handleSearch}>
					<Search />
				</button>
			</div>
			<div className="HomePage_middle_text" hidden>
				Veja abaixo os tipos de conteúdo disponíveis
			</div>
		</div>
	);
};

export default SearchContainer;
