import React from "react";
import Menu from "../components/common/Menu.js";
import Footer from "../components/common/Footer.js";
import ProjectInfo from "../components/about/ProjectInfo.js";

const AboutPage = () => {
	return (
		<div>
			<Menu />
			<ProjectInfo />
			<Footer />
		</div>
	);
};

export default AboutPage;
