const ErrorPopUp = () => {
	return (
		<div className="ErrorPopUp_Content">
			<h5>
				Preencha todos os campos para que o cadastro possa ser realizado.{" "}
			</h5>
			<p>
				<strong>Todos os campos</strong> são obrigatórios, exceto no caso dos
				links, onde apenas 1 dos 4 itens deve ser preenchido
			</p>
			<p>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="60"
					height="60"
					fill="#3b82f5"
					class="bi bi-emoji-smile"
					viewBox="0 0 16 16"
				>
					<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
					<path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.5 3.5 0 0 0 8 11.5a3.5 3.5 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5" />
				</svg>
			</p>
		</div>
	);
};
export default ErrorPopUp;
