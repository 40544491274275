import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import WelcomePage from "./pages/WelcomePage";
import HomePage from "./pages/HomePage";
import SearchPage from "./pages/SearchPage";
import RegisterPage from "./pages/RegisterPage";
import MyRecordsPage from "./pages/MyRecordsPage";
import SettingsPage from "./pages/SettingsPage";
import EditPage from "./pages/EditPage";
import ProtectedRoute from "./config/ProtectedRoute.js";
import AboutPage from "./pages/AboutPage.js";
import NotFoundPage from "./pages/NotFoundPage.js";
import DeletedResourcesPage from "./pages/DeletedResourcesPage.js";
import ErrorPage from "./pages/ErrorPage.js";
import ResourceNotFound from "./components/search/ResourceNotFound.js";
import ResourceNotFoundPage from "./pages/ResourceNotFoundPage.js";

const Rotas = () => {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<WelcomePage />} />
				<Route
					path="/home"
					element={
						<ProtectedRoute
							element={() => <HomePage />}
							roles={["COMMON", "ADMINISTRADOR", "EDITOR"]}
						/>
					}
				/>
				<Route
					path="/search"
					element={
						<ProtectedRoute
							element={() => <SearchPage />}
							roles={["COMMON", "ADMINISTRADOR", "EDITOR"]}
						/>
					}
				/>
				{/* <Route path="/Exhibition/:referencia" element={<ExhibitionPage />} /> */}
				<Route
					path="/register"
					element={
						<ProtectedRoute
							element={() => <RegisterPage />}
							roles={["ADMINISTRADOR", "EDITOR"]}
						/>
					}
				/>
				<Route
					path="/myrecords"
					element={
						<ProtectedRoute
							element={() => <MyRecordsPage />}
							roles={["ADMINISTRADOR", "EDITOR"]}
						/>
					}
				/>
				<Route
					path="/deletedresources"
					element={
						<ProtectedRoute
							element={() => <DeletedResourcesPage />}
							roles={["ADMINISTRADOR"]}
						/>
					}
				/>
				<Route
					path="/settings"
					element={
						<ProtectedRoute
							element={() => <SettingsPage />}
							roles={["ADMINISTRADOR"]}
						/>
					}
				/>
				<Route
					path="/edit/:referencia"
					element={
						<ProtectedRoute
							element={() => <EditPage />}
							roles={["ADMINISTRADOR", "EDITOR"]}
						/>
					}
				/>
				<Route path="/about" element={<AboutPage />} />
				<Route path="/error" element={<ErrorPage />} />
				<Route path="/ResourceNotFound" element={<ResourceNotFoundPage />} />
				<Route path="*" element={<NotFoundPage />} />
			</Routes>
		</Router>
	);
};

export default Rotas;
