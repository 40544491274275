import React from "react";
import SearchResultCard from "../search/SearchResultsCard.js";
import FadeIn from "react-fade-in";

const SearchResults = ({ results }) => {
	const handleRemove = (id) => {
		let removedElement = document.getElementById(`SearchResultCard${id}`);
		removedElement.style.display = "none";
	};
	return (
		<div className="SearchPage_search_results">
			<FadeIn
				className="SearchPage_Fade_In"
				childClassName="SearchPage_Fade_In_Child"
			>
				{results.map((result) => (
					<SearchResultCard
						identification={`SearchResultCard${result.id}`}
						key={result.id}
						result={result}
						onRemove={handleRemove}
					/>
				))}
			</FadeIn>
		</div>
	);
};

export default SearchResults;
