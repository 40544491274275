import React from "react";
import Routes from "./routes";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { UserProvider } from "./context/UserContext.js";
import msalConfig from "./config/msalConfig.js";

const msalInstance = new PublicClientApplication(msalConfig);

function App() {
	return (
		<MsalProvider instance={msalInstance}>
			<UserProvider>
				<Routes />
			</UserProvider>
		</MsalProvider>
	);
}

export default App;
