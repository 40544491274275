import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const SearchForm = ({ projects, types, showBtn, onSearch }) => {
	const [searchTerm, setSearchTerm] = useState("");
	const [referenceNumber, setReferenceNumber] = useState("");
	const [selectedProject, setSelectedProject] = useState("");
	const [resourceType, setResourceType] = useState("");
	const navigate = useNavigate();
	const location = useLocation();

	const handleButtonClick = (e) => {
		e.preventDefault();
		const params = {
			searchTerm,
			referenceNumber,
			selectedProject,
			resourceType,
		};

		if (onSearch) {
			onSearch(params); // Chama a função de busca do componente pai com os parâmetros
		}
	};

	const params = new URLSearchParams(location.search);
	const initialSearchTerm = params.get("term") || "";

	return (
		<form onSubmit={handleButtonClick} className="SearchPage_container_form">
			<input
				type="text"
				placeholder="Palavra-chave ou tag"
				value={searchTerm || initialSearchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
			/>
			<select
				value={resourceType}
				onChange={(e) => setResourceType(e.target.value)}
			>
				<option value="">Selecione o tipo de recurso</option>
				{types.map((type) => (
					<option key={type.id} value={type.tipo}>
						{type.tipo}
					</option>
				))}
			</select>
			<select
				value={selectedProject}
				onChange={(e) => setSelectedProject(e.target.value)}
			>
				<option value="">Selecione um projeto</option>
				{projects.map((project) => (
					<option key={project.id} value={project.codigo}>
						{project.codigo} - {project.nome}
					</option>
				))}
			</select>
			<input
				type="number"
				placeholder="Referência"
				value={referenceNumber}
				onChange={(e) => setReferenceNumber(e.target.value)}
			/>

			{!showBtn && (
				<div className="SearchPage_div_btn">
					<button type="submit" onClick={handleButtonClick}>
						Buscar
					</button>
				</div>
			)}
		</form>
	);
};

export default SearchForm;
