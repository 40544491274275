import React, { useState, useEffect, useCallback, useRef } from "react";
import Menu from "../components/common/Menu";
import SearchForm from "../components/search/SearchForm";
import SearchResults from "../components/search/SearchResults";
import Footer from "../components/common/Footer.js";
import loader from "../assets/images/loading_blue.png";
import Sad from "../assets/images/sad.svg";
import NotFoundImage from "../assets/images/not-found.png";
import { useNavigate } from "react-router-dom";
import { useUser } from "../context/UserContext.js";

const SearchPage = () => {
	const [results, setResults] = useState([]);
	const [page, setPage] = useState(1);
	const loaderRef = useRef(null);
	const [projects, setProjects] = useState([]);
	const [types, setTypes] = useState([]);
	const [hasMore, setHasMore] = useState(true);
	const [initialSearchDone, setInitialSearchDone] = useState(false);
	const [parametros, setParametros] = useState({});
	const [inSearch, setInSearch] = useState(false);
	const [notFound, setNotFound] = useState(false);
	const [blankForm, setBlankForm] = useState(false);
	const [actualPage, setActualPage] = useState("");
	const navigate = useNavigate();
	const { user } = useUser();

	useEffect(() => {
		const fetchProjects = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BASE_URL}/list/projects/${user.encodedEmail}`,
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
				if (!response.ok) {
					throw new Error("Erro ao buscar projetos");
				}

				const projectsData = await response.json();
				setProjects(projectsData);
			} catch (error) {
				console.error("Erro ao buscar projetos:", error);
			}
		};
		const fetchTypes = async () => {
			const response = await fetch(
				`${process.env.REACT_APP_BASE_URL}/list/types/${user.encodedEmail}`,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			if (!response.ok) {
				throw new Error("Erro ao buscar projetos");
			}
			const typesData = await response.json();
			setTypes(typesData);
		};

		fetchProjects();
		fetchTypes();
	}, []);

	const handleSearch = useCallback(
		async (params, nextPage) => {
			// if (!hasMore) return;

			if (
				params.referenceNumber === "" &&
				params.resourceType === "" &&
				params.searchTerm === "" &&
				params.selectedProject === ""
			) {
				setBlankForm(true);
				return;
			}

			// setInSearch(true);
			setNotFound(false);
			setBlankForm(false);

			try {
				if (hasMore) {
					setInSearch(true);
					const response = await fetch(
						`${process.env.REACT_APP_BASE_URL}/find/resources/${user.encodedEmail}`,

						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify({ params, page }),
						}
					);

					if (!response.ok) navigate("/ResourceNotFound");

					const data = await response.json();

					if (response.status === 404) {
						setNotFound(true);
						setInSearch(false);
						setHasMore(false);
						setInitialSearchDone(true);
						setActualPage("");
					} else {
						setNotFound(false);
						setResults((prevResults) => [...prevResults, ...data.resultados]);
						setPage((prevPage) => prevPage + 1);
						setInSearch(false);
						setHasMore(page < data.totalPaginas);
						let quantity =
							data.paginaAtual === 1
								? data.resultados.length
								: results.length + data.resultados.length;
						setActualPage(`Exibindo ${quantity} de ${data.totalItens}`);

						setInitialSearchDone(true);
					}
				} else {
					return;
				}
			} catch (error) {
				console.log(error.message);
				navigate("/notfound");
			}
		},
		[page]
	);

	const handleObserver = useCallback(
		(entries) => {
			const target = entries[0];
			if (target.isIntersecting && hasMore) {
				handleSearch(parametros, page);
			}
		},
		[parametros, handleSearch, page, hasMore]
	);

	useEffect(() => {
		if (initialSearchDone) {
			const options = {
				root: null,
				rootMargin: "20px",
				threshold: 1.0,
			};
			const observer = new IntersectionObserver(handleObserver, options);
			if (loaderRef.current) observer.observe(loaderRef.current);

			return () => {
				if (loaderRef.current) observer.unobserve(loaderRef.current);
			};
		}
	}, [handleObserver, initialSearchDone]);

	// const handleRemove = (id) => {
	// 	console.log(`Aqui no handleRemove: ${id}`);
	// 	let updatedResults = results.filter((e) => e.id !== id);
	// 	setResults(updatedResults);
	// };

	return (
		<div>
			<div>
				<Menu />
				<div
					id="SearchPage_main_container"
					className="SearchPage_main_container"
				>
					<h5>Realize a sua busca</h5>
					<SearchForm
						projects={projects}
						types={types}
						showBtn={inSearch}
						onSearch={(newParams) => {
							setResults([]);
							setPage(1);
							setHasMore(true);
							setParametros(newParams);
							handleSearch(newParams, 1);
						}}
					/>
					<div className="SearchPage_divisor"></div>
					<SearchResults results={results} />
					{inSearch && (
						<div>
							<img id="loader_blue" alt="Loader" src={loader} />
						</div>
					)}
					{notFound && (
						<div className="SearchPage_NotFound">
							<img id="notFoundImage" src={NotFoundImage} alt="not found" />
							<div className="SearchPage_NotFound_Text">
								<img src={Sad} alt="sad face" style={{ marginRight: "1em" }} />
								Nenhum recurso encontrado
							</div>
						</div>
					)}
					{blankForm && (
						<div className="SearchPage_NotFound">
							<img id="notFoundImage" src={NotFoundImage} alt="not found" />
							<div className="SearchPage_NotFound_Text">
								<img src={Sad} alt="sad face" style={{ marginRight: "1em" }} />
								Especifique pelo menos um critério para a busca
							</div>
						</div>
					)}
					{hasMore && <div ref={loaderRef}></div>}
					{actualPage && (
						<div className="SearchPage_actualPage">{actualPage}</div>
					)}
				</div>
				<Footer />
			</div>
		</div>
	);
};

export default SearchPage;
