import React from "react";
import Menu from "../components/common/Menu.js";
import Footer from "../components/common/Footer.js";
import GeneralError from "../components/common/GeneralError.js";

const ErrorPage = () => {
	return (
		<div className="ErrorPage_Container">
			<Menu />
			<GeneralError />
			<Footer />
		</div>
	);
};

export default ErrorPage;
